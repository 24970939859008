import { Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';

const _404Page = () => {

    return (
        <div className='bg-[#1b1b1b] h-[100vh] transition-all'>
            <div className='flex flex-col justify-center w-full h-full items-center'>
                <div className='text-orange font-bold'>
                    <h1 className='text-center text-[12rem] leading-[1.15em] shake-text'>404</h1>
                    <p className='text-white text-center'>
                        🧨 Uh oh, something went wrong! 🧨
                    </p>
                    <Link to={"/"} className="mt-2 items-center gap-1 hover:text-orange text-veryLightOrange flex justify-center">
                        <MdArrowOutward className="-rotate-90" />
                        <span className="font-bold uppercase text-heading6">Let's go back!</span>
                    </Link>
                </div>
            </div>
        </div>

    );

};
export default _404Page;