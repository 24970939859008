import Header from '../components/Header';
import Hero from '../components/HomeHero';
import Partners from '../components/HomePartners';
import HomeServices from '../components/HomeServices';
import OurWork from '../components/OurWork';
import Team from '../components/Team';
import MeetDiogo from '../components/MeetDiogo';
import WorkWithUs from '../components/WorkWithUs';
import Footer from '../components/Footer';
import { useEffect, useState } from 'react';
import LoadingPulse from '../components/Loading/LoadingPulse';

const HomePage = () => {
    const [loadingPulse, setLoadingPulse] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            document.documentElement.style.overflow = 'auto';
            setLoadingPulse(false)
        }, 2000);
        // Clean up the event listener when the component unmounts
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <>
        {loadingPulse && <LoadingPulse />}
        <div className='absolute w-full bg-[#1b1b1b] -z-50 overflow-x-clip flex flex-col items-center'>
            <div className='max-w-[1920px] '>
                <div className='mx-6 sm:mx-16 xl:mx-36'>
                    <Header />
                    <Hero />
                    <Partners />
                </div>
                <HomeServices />
                <OurWork />
                <Team />
                <MeetDiogo />
                <WorkWithUs scrollBreakpoint={81} scrollBreakpointTabletLarge={86} scrollBreakpointTablet={84} scrollBreakpointMobile={84} />
            </div>
            <Footer />
        </div>
        </>
    );
}

export default HomePage