import Header from '../components/AgenciesHeader';
import Hero from '../components/AgenciesHero';
import Footer from '../components/Footer';
import Partners from '../components/AgenciesPartners'
import Services from '../components/AgenciesServices'
import WorkWithUs from '../components/WorkWithUs'
import { useEffect, useState } from 'react';
import LoadingPulse from '../components/Loading/LoadingPulse';

const AgenciesPage = () => {
    const [loadingPulse, setLoadingPulse] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const timeoutId = setTimeout(() => {
            document.documentElement.style.overflow = 'auto';
            setLoadingPulse(false)
        }, 2000);
        // Clean up the event listener when the component unmounts
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <>
            {loadingPulse && <LoadingPulse />}
            <div className='absolute w-full bg-[#1b1b1b] -z-50 overflow-x-clip '>
                <div className='mx-6 mb-8 sm:mx-16 xl:mx-36'>
                    <Header />
                    <Hero />
                </div>
                <Partners />
                <Services />
                <WorkWithUs scrollBreakpoint={57} scrollBreakpointTabletLarge={58} scrollBreakpointTablet={66} scrollBreakpointMobile={64.2} />
                <Footer />
            </div>
        </>
    );
}

export default AgenciesPage