import { createContext, useState, useEffect } from "react";
import stringsGlobal from "./strings.json"

const LanguageContext = createContext();

// CREATE A WRAPPER COMPONENT
function LanguageProviderWrapper(props) {

    const [loading, setLoading] = useState(false);
    //const [language, setLanguage] = useState(localStorage.getItem('appLanguage') || "EN")
    const [language, setLanguage] = useState("EN")
    const [strings, setStrings] = useState(stringsGlobal["EN"])

    useEffect(() => {
        localStorage.setItem('appLanguage', language);
        setStrings(stringsGlobal[language]);
    }, [language])

    const startLoading = () => {
        setLoading(true);
    };

    const stopLoading = () => {
        setLoading(false);
    };

    return (
        <LanguageContext.Provider value={{
            strings,
            language,
            setLanguage, loading, startLoading, stopLoading
        }}>
            {props.children}
        </LanguageContext.Provider>
    )
}

export { LanguageContext, LanguageProviderWrapper };