import "./Loading.css";

const LoadingPulse = () => {

    return (
        <div>
            <div className='h-screen fixed bg-[#101010] flex w-full animationUp'></div>
            <img className='absolute left-1/2 z-[999] top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[142px] animationLogo' src='images/logo.svg' alt='Logo' />
        </div>
    );
};

export default LoadingPulse;