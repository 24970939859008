import React, { useContext } from 'react';
import { LanguageContext } from '../language.context';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';
import Header from '../components/Header';

const PrivacyPolicy = () => {

    const { strings } = useContext(LanguageContext);

    return (
        <div className='bg-[#1b1b1b] overflow-x-clip'>
            <div className="mx-6 lg:mx-36">
                <Header />
                <Link to={"/"} className="absolute z-40 flex items-center gap-1 transition-all top-6 lg:top-24 hover:text-orange text-veryLightOrange">
                    <MdArrowOutward className="-rotate-90" />
                    <span className="font-bold uppercase text-heading6">{strings.backToHome}</span>
                </Link>
            </div>
            <div className='flex flex-col w-full h-full px-6 py-10 text-white sm:items-center '>
                <h1 className='font-bold text-orange bg-clip-text sm:text-heading1 text-heading2'>{strings.privacyPolicy.heading1}</h1>
                <div className="w-full mt-6 sm:w-3/5">
                    <div>
                        {strings.privacyPolicy.subheading1}
                    </div>
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.subheading2}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheadingA}
                    <br />
                    <div className='mt-1'></div>
                    {strings.privacyPolicy.subheading3}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading2}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading4}<br />
                    <div className='mt-1'></div>
                    {strings.privacyPolicy.subheading5}<br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading3}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading6}
                    <br />
                    <div className='mt-1'></div>
                    {strings.privacyPolicy.subheading7}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading4}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading8}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading5}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading9}
                    <br />
                    <div className='mt-1'></div>
                    {strings.privacyPolicy.subheading10}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading6}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading11}
                    <br />
                    <div className='mt-1'></div>
                    {strings.privacyPolicy.subheading12}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading7}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading13}
                    <br />
                    <div className='mt-1'></div>
                    {strings.privacyPolicy.subheading14}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading8}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading15}
                    <br />
                    <div className='mt-1'></div>
                    {strings.privacyPolicy.subheading16}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading9}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading18}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading10}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading19}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading11}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading20}
                    <br />
                    <div className='mt-1'></div>
                    <div className='ml-4'>
                        {strings.privacyPolicy.listItems1}
                        <br />
                        <div className='mt-1'></div>
                        {strings.privacyPolicy.listItems2}
                        <br />
                        <div className='mt-1'></div>
                        {strings.privacyPolicy.listItems3}
                        <br />
                        <div className='mt-1'></div>
                        {strings.privacyPolicy.listItems4}
                        <br />
                        <div className='mt-1'></div>
                    </div>
                    {strings.privacyPolicy.additionalInfo}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading12}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading21}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading13}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading22}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading14}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading23}
                    <br />
                    <div className='mt-1'></div>
                    <div className='ml-4'>
                        {strings.privacyPolicy.listItems5}
                        <br />
                        <div className='mt-1'></div>
                        {strings.privacyPolicy.listItems6}
                    </div>
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading15}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading24}
                    <br />
                    <div className='mt-1'></div>
                    {strings.privacyPolicy.additionalInfo2}
                    <br />
                    <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text leading-[3rem] text-heading4 font-bold'>
                        {strings.privacyPolicy.heading16}
                    </span>
                    <br />
                    {strings.privacyPolicy.subheading25}
                    <div className='ml-4'>
                        Firefox: {" "}
                        <a className='underline text-veryLightOrange' href={strings.privacyPolicy.browsers.linkFirefox}> {strings.privacyPolicy.browsers.linkFirefox}</a>
                        <br />
                        <div className='mt-1'></div>
                        Chrome: {" "}
                        <a className='underline text-veryLightOrange' href={strings.privacyPolicy.browsers.linkChrome}>{strings.privacyPolicy.browsers.linkChrome}</a>
                        <br />
                        <div className='mt-1'></div>
                        Internet Explorer: {" "}
                        <a className='underline text-veryLightOrange' href={strings.privacyPolicy.browsers.linkIE}>{strings.privacyPolicy.browsers.linkIE}</a>
                        <br />
                        <div className='mt-1'></div>
                        Microsoft Edge: {" "}
                        <a className='underline text-veryLightOrange' href={strings.privacyPolicy.browsers.linkEdge}>{strings.privacyPolicy.browsers.linkEdge}</a>
                        <br />
                        <div className='mt-1'></div>
                        Safari: {" "}
                        <a className='underline text-veryLightOrange' href={strings.privacyPolicy.browsers.linkSafari}>{strings.privacyPolicy.browsers.linkSafari}</a>
                        <br />
                        <div className='mt-1'></div>
                        Opera: {" "}
                        <a className='underline text-veryLightOrange' href={strings.privacyPolicy.browsers.linkOpera}>{strings.privacyPolicy.browsers.linkOpera}</a>
                    </div>
                    {strings.privacyPolicy.additionalInfo3}
                    <br />
                    <div className='mt-6' />
                    <span className='text-orange bg-clip-text leading-[1rem] text-heading5 font-bold'>
                        {strings.privacyPolicy.subheading17}
                    </span>
                </div>
            </div>
            <Footer />
        </div>
    );

};

export default PrivacyPolicy;