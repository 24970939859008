import React from 'react';
import { Link } from "react-router-dom";
import { useState, useContext } from 'react';
import { LanguageContext } from './../language.context';

const Header = () => {
    const { strings, language, setLanguage } = useContext(LanguageContext);
    const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false)
    const handleChange = (e) => {
        const language = e.target.value
        setLanguage(language)
    }

    return (
        <header className='header'>
            <div className="flex flex-row justify-center">
                <nav className="relative flex items-center justify-between w-full mt-8 sm:justify-center">

                    {/* LOGO */}
                    <Link to="/" className='z-50 cursor-pointer'>
                        <div className='flex items-center' >
                            <img className='h-6 mr-2 invert brightness-0' src={"./images/logo/logo.png"} alt='Dionamite Logo' />
                            <p className='font-extrabold text-white text-heading2'>Dionamite</p>
                        </div>
                    </Link>

                    <div className="absolute right-0">
                        <button className="text-white transition-transform duration-200 ease-linear transform hover:scale-110">
                            <select
                                value={language}
                                onChange={handleChange}
                                className='p-2 bg-black rounded-lg'>
                                <option value={"PT"}>🇵🇹</option>
                                <option value={"EN"}>🇬🇧</option>
                            </select>
                        </button>
                    </div>
                </nav>
            </div>
        </header>
    );
};




export default Header;