import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../language.context';

const Body = () => {

    const { strings } = useContext(LanguageContext);

    return (
        <div className='grid items-center flex-grow xl:grid-cols-2 grid-cols-1 h-[90%] mt-8 w-full'>
            <div className='flex flex-col'>
                <div className='w-full'>
                    <h1 className='font-bold sm:leading-[5rem] hero1 leading-[4.5rem] text-transparent bg-gradient-to-b whiteGrayGradient bg-clip-text sm:text-6xl text-5xl '>
                        {strings.agencies.hero.heading}
                        <span className='font-bold sm:leading-[5rem] leading-[4.5rem] text-transparent bg-gradient-to-r orangeGradient bg-clip-text sm:text-6xl text-5xl '>
                            {strings.agencies.hero.heading2}
                        </span>
                        {strings.agencies.hero.heading3}
                    </h1>
                    <hr className='border-0 my-6 h-[1px] bg-gradient-to-r hero2 from-orange via-orange-[#FFC149]' />
                    <h2 className='font-bold text-white text-heading4 hero2'>
                        {strings.agencies.hero.subheading1}
                        <span className='text-transparent bg-gradient-to-r orangeGradient bg-clip-text'>{strings.agencies.hero.subheading2} </span>
                        {strings.agencies.hero.subheading3}
                    </h2>
                    <a href="/#form" className="z-30 inline-block p-3 px-10 font-bold tracking-wider text-center text-white uppercase transition-opacity cursor-pointer hero3 sm:ml-0 mt-7 bg-gradient-to-r darkOrangeGradient text-buttonText hover:opacity-75" style={{ clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)' }} >
                        {strings.agencies.hero.button}
                    </a>
                </div>
            </div>
            <div className='relative'>
                <img src="images/hero/heroAgencies.png" className='hidden mt-4 ml-6 select-none hero3 xl:mt-0 xl:block' alt='Dionamite'/>
                <img src='images/hero/dinamiteAgency.png' className='hidden xl:block absolute -left-96 -top-32 -z-40 h-[48rem] opacity-70 select-none' alt='Web Agency' />
                <img src='images/hero/dinamiteAgency2.png' className='hidden xl:block absolute right-32 -top-14 -z-40 h-[32rem] opacity-70 select-none' alt='Web Agency' />
                <img src='images/hero/dinamiteAgency3.png' className='absolute right-0 hidden h-64 select-none xl:block top-52 -z-40 opacity-70' alt='Web Agency' />
            </div>
        </div >
    );
};

export default Body;
