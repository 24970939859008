import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LanguageContext } from './language.context';
import axios from 'axios'
import HomePage from './pages/HomePage';
import _404Page from './pages/404Page';
import MagnaLandingPage from './pages/MagnaLandingPage';
import MrWipeLandingPage from './pages/MrWipeLandingPage';
import AgenciesPage from './pages/AgenciesPage';
import WebAppsPage from './pages/WebAppsPage';
import FormSubmited from './pages/FormSubmited';
import { ToastContainer } from 'react-toastify';
import Loading from './components/Loading/Loading';
import CookiesNotification from './components/Cookies';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LoadingPulse from './components/Loading/LoadingPulse';

function App() {
  const [loadingPulse, setLoadingPulse] = useState(true);

  const { strings, language, setLanguage, loading, startLoading, stopLoading } = useContext(LanguageContext);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  // Setar a língua do utilizador
  /*
  useEffect(() => { 
    document.documentElement.style.overflow = 'hidden';
    if (!localStorage.getItem("appLanguage")) {

      axios
        .get("https://ipapi.co/json/")
        .then((response) => {

          const { data } = response
          const { country } = data

          if (country == "PT" || country == "AO" || country == "MZ" || country == "BR"
            || country == "ST" || country == "GW" || country == "CV") {
            setLanguage("PT")
          }
          else {
            setLanguage("EN")
          }
        })
        .catch((error) => {
          console.log(error);
        });

    }
    window.scrollTo(0, 0);
    const timeoutId = setTimeout(() => {
      
      document.documentElement.style.overflow = 'auto';
      setLoadingPulse(false)
    }, 2000);


    return () => { clearTimeout(timeoutId); }// Cleanup function to clear the timeout when component unmounts or when useEffect runs again
  }, []);
  */

  return (
    <>
      <LoadingPulse />
      <Router>
        <ToastContainer />
        {loading && <Loading />}
        {!loading &&
          <>
            <CookiesNotification />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/agencies" element={<AgenciesPage />} />
              <Route path="/contact-success" element={<FormSubmited />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="*" element={<_404Page />} />
            </Routes>
          </>
        }
      </Router>
    </>
  );
}

export default App;
